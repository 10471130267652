import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {graphql} from "gatsby";

export const data = graphql`query FeesQuery {
  file(relativePath: {eq: "Tariff-of-Second-Charge-Mortgage-Charges.pdf"}) {
    publicURL
  }
}
`

const FeesPage = ({ data }) => (
    <Layout>
        <Seo title={'Nemo’s standard fees'} description={'Nemo personal Finance loans fees for administering our secured loans. Click here now to view a breakdown of all the service fees and their relative costs.'} lang={'en-gb'} />
        <section className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>Our Fees</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <div className="intro">
                            <p className="leading">Our Tariff of Fees and Charges aims to align with industry best practice principles
                            recommended by UK Finance and Which? Our goal is to ensure that our fees and charges are
                            easy for you to understand. A similar document is used across the industry to help you compare
                            mortgage products. As you review the fees charged by other lenders, you may notice some that
                            are not listed in our tariff below. This means we do not charge these fees.</p>
                        </div>
                    </div>
                <p>To view our current fees and charges, please <a href={data.file.publicURL} target="_blank">click here.</a></p>

                <p>In addition to the fees listed here, there may be other expenses (as defined in the Terms and
                Conditions) that you may incur in relation to your Mortgage Agreement. These additional
                expenses will form part of your outstanding mortgage debt and must be paid to us in full when
                they become due. The Terms and Conditions of your second charge mortgage can be found on
                the back of your Mortgage Agreement. If you need a copy of your Mortgage Agreement, please
                <a href="/contact-us"> contact us.</a></p>
                <p>
                Fees and information are correct as of September 2023 and are subject to change to reflect
                changes in the costs of these services at Nemo Personal Finance. Please <a href="/contact-us">contact us</a> if you have
                any questions.
                </p>
                </div>
                <div className="bdrBot"></div>
            </div>
        </section>
    </Layout>
)

export default FeesPage;